@import "./functions";

.f8{
  font-size: 8px;
}
.f10{
  font-size: 10px;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}
.f24 {
  font-size: 24px;
}
.f28 {
  font-size: 28px;
}
.f32 {
  font-size: 32px;
}
.f36 {
  font-size: 36px;
}
.f40 {
  font-size: 40px;
}
.f50 {
  font-size: 50px;
}
.f60 {
  font-size: 60px;
}
.f80 {
  font-size: 80px;
}
.f400 {
  font-weight: 400;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.f700 {
  font-weight: 700;
}

.f800 {
  font-weight: 800;
}

.clr-black{
  color: #000;
}