@use 'sass:math';
@import '../variables.scss';

@mixin nomargin {
  margin: 0;
}

@mixin nopadding {
  padding: 0;
}

@mixin nomargin-nopadding {
  @include nomargin;
  @include nopadding;
}

@mixin debug {
  border: 1px solid red;
}

@mixin lato {
  font-family: Lato, 'sans-serif';
}

@mixin open-sans {
  font-family: 'Open Sans';
}

@mixin montserrat {
  font-family: 'Montserrat';
  font-size: 25px/30px;
}

@mixin no-list-type {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin inline-list {
  @include no-list-type;
  display: flex;

  li {
    padding: 5px 10px;
  }
}

@mixin flex-spread {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  flex-direction: column;
}

@mixin flex-space-around {
  justify-content: space-around;
}

@mixin white-box {
  box-shadow: 0px 10px 27px #00000014;
  background: #ffffff;
  padding: 20px 10px;
  margin: 0 10px;
}

@mixin bordered-white-box {
  @include white-box;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

@mixin before-icon {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

@function size($currentSize) {
  @return math.div($currentSize, 16px) * 0.65 * 1rem;
}

@mixin lato {
  font-family: Lato;
}

@mixin os {
  font-family: 'Open Sans', sans-serif;
}

@mixin poppins {
  font-family: 'Poppins', sans-serif;
}

@mixin uppercase {
  text-transform: uppercase;
}

@mixin fs($size, $lineHeight: 150%) {
  font-size: size($size);
  line-height: $lineHeight;
}

@mixin centered-image {
  display: block;
  margin: 0 auto;
}

@mixin rounded($size, $color) {
  width: $size;
  height: $size;
  background-color: $color;
  border-radius: 50%;
  text-align: center;
  display: block;
}

@mixin fw($fw) {
  font-weight: $fw;
}

@mixin application-table-widths {
  @include flex-spread;

  padding: 10px;
  justify-content: flex-start;

  .checkbox {
    width: 50px;
  }

  .applicants {
    width: 25%;
  }

  .profile {
    width: 55%;
  }

  .action {
    width: 18%;
    padding-left: 40px;
  }
}

@mixin zero {
  margin: 0;
  padding: 0;
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin large {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin themed-scroller {
  scrollbar-width: thin;
  scrollbar-color: $themed-blue $themed-grey-light;
  scroll-behavior: smooth;

  ::-webkit-scrollbar-thumb {
    background-color: $themed-blue;
    border-radius: 20px;
    border: 3px solid $white-color;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: $white-color;
  }


  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin fadeInAnim {
  animation: fadeIn linear 0.8s;
  -webkit-animation: fadeIn linear 0.8s;
  -moz-animation: fadeIn linear 0.8s;
  -o-animation: fadeIn linear 0.8s;
  -ms-animation: fadeIn linear 0.8s;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin spinnerAnim {
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
}

@keyframes loaderBlink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}


@-o-keyframes loaderBlink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}


@-ms-keyframes loaderBlink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

@mixin loaderDotAnim {
  animation: loaderBlink 1s both infinite;
  -webkit-animation: loaderBlink 1s both infinite;
  -moz-animation: loaderBlink 1s both infinite;
  -o-animation: loaderBlink 1s both infinite;
  -ms-animation: loaderBlink 1s both infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-o-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-ms-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@mixin shakeAnim {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
  -webkit-animation: shake 0.5s;
  -moz-animation: shake 0.5s;
  -o-animation: shake 0.5s;
  -ms-animation: shake 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(253, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

@mixin tabbar {
  border-bottom: 2px solid #ddd;
  margin: 10px 0;

  ul,
  ol {
    display: flex;
    width: 100%;
    list-style-type: none;
    align-items: flex-end;
    margin: 0;
    padding: 0;

    // Dont display any icons
    svg {
      display: none;
    }

    li {
      line-height: 1.7;
      margin-bottom: 0;

      a {
        display: inline-block;
        padding: 5px 15px;
        color: #707070;
        position: relative;
        top: 2px;
        font-weight: normal;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      &.active a {
        color: #3a50fb;
        border-bottom: 2px solid #3A50FB;
      }
    }


  }
}

@mixin boxshadow {
  box-shadow: 0px 8px 16px rgba(58, 80, 251, 0.16);
}