@import "./functions";

.btn:not(.btn-sm) {
  padding: 10px 30px;
}

.btn-home-primary {
  @include montserrat;

  font-size: 23px/29px;
  background-color: #374bff;
  border-color: #374bff;
  color: #fff;
  text-align: center;
  padding-left: 55px;
  padding-right: 55px;

  &.btn-disabled {
    border-color: darkgray;
    background-color: #fff;
    color: darkgray;
  }

  &:hover {
    color: #ffffff;
  }
}

.btn-home-secondary {
  background: #000000;
  padding: 5px 20px;
  color: #fff;

  &:hover {
    color: #fff;
    background: lighten(#000, 20%);
  }
}

.btn-home-tertiary {
  background: transparent;
  padding: 5px 20px;
  color: #3153c4;
  border: 4px solid #dbdbdb;
  font-weight: bold;

  &:hover {
    background: lighten(#dbdbdb, 20%);
  }
}

.invite-button {
  border: 1px solid #3153c4;
  border-radius: 0;
  color: #3153c4;
  padding: 1px 10px;

  &:hover {
    color: #3153c4;
  }
}

.btn-blue-plain {
  background: transparent;
  border: 1px solid #4362c9;
  border-radius: 6px;
  color: #3153c4;
  font-family: Lato;
  font-weight: bold;
}

.btn-large {
  padding: 10px 40px;
}

.btn-blue-outlined {
  @include os;
  @include fs(22px, 28px);
  @include fw(600);

  color: #374bff;
  border: 2px solid #374bff;
  background-color: #fff;

  &.btn-danger {
    border-color: #212121;
    color: #212121;
  }

  &.btn-warning {
    border-color: #dc3545;
    color: #dc3545;
  }

  &.btn-disabled {
    border-color: darkgray;
    color: darkgray;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &.btn-small {
    padding: size(3px) size(25px) !important;
    border-width: 1px;
    font-weight: normal;
  }
}

.btn-primary-large {
  @include montserrat;

  font-size: 23px/29px;
  background-color: #374bff;
  border-color: #374bff;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  padding: 12px 10px;
  width: 441px;
  height: 52px;
  font-size: 18px;
  font-weight: 700;
  &.btn-disabled {
    border-color: darkgray;
    background-color: #fff;
    color: darkgray;
  }
}

.btn-primary-small {
  @include montserrat;

  font-size: 23px/29px;
  background-color: #374bff;
  border-color: #374bff;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  padding: 12px 10px;
  width: 360px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  &.btn-disabled {
    border-color: darkgray;
    background-color: #fff;
    color: darkgray;
  }
}

.plain-link {
  border: 0;
  background-color: none;
  color: #374bff;
}

.plain-button,
.clear-button,
.btn-clear {
  @include lato;
  background: none;
  border: none;
  box-shadow: none;
  color: #212121;
}

.facebook-login-button {
  background-color: #4267b2;
  width: auto;
  border-color: #4267b2;
}

.btn-gray-bold,
.button-gray-bold {
  @include fw(700);
  @include fs(22px);
  text-transform: uppercase;
  color: lighten(#000, 30%);
}

.btn-full-width {
  display: block;
  width: 100%;
}

.btn-dgr-outline {
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff3434;
  border: solid 1px #ff3434;
}

.btn-blue-filled {
  border-radius: 5px;
  border: solid 1px #374bff;
  background-color: #374bff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.btn-blue-outlined {
  cursor: pointer;
  border: solid 1px #374bff;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #374bff;
}

.fixed-dy-btns {
  width: 124px;
  height: 43px;
}

.btn-blue-outlined:hover {
  background-color: #374bff;
  color: #fff;
}

.btn-blue-filled:hover {
  background-color: #ffffff;
  color: #374bff;
}
