@import '../variables.scss';
@import './functions.scss';

$icons: '../assets/icons/';
$images: '../assets/images/';

.vz-icons {
  user-select: none;
  user-select: none;

  &.themed-blue {
    filter: $themed-blue-filter !important;
  }

  &.themed-red {
    filter: $themed-red-filter !important;
  }

  &.white {
    filter: $white-filter !important;
  }

  &.black {
    filter: $black-filter !important;
  }

  &.dark {
    filter: $themed-dark-filter;
  }

  &.themed-grey-dark {
    filter: $themed-grey-dark-filter;
  }

  &.close-mark {
    content: url($icons + '/close-blue.svg');
  }

  &.voiz-verified {
    content: url($icons + '/voiz-verified.png');
  }

  &.envelope-closed {
    content: url($images + 'envelope-closed.svg');
  }

  &.key {
    content: url($images + 'key.svg');
  }

  &.caution {
    content: url($icons + 'caution.svg');
  }

  &.user {
    content: url($images + 'user.svg');
  }

  &.cake {
    content: url($icons + 'cake-grey.svg');
  }

  &.gender {
    content: url($icons + 'gender-grey.svg');
  }

  &.languages {
    content: url($icons + 'language-grey.svg');
  }

  &.location {
    content: url($icons + 'location-grey.svg');
  }

  &.mobile-filled {
    content: url($images + 'mobile-filled.svg');
  }

  &.cerification-badged {
    content: url($images + 'cerification-badged.svg');
  }

  &.organization {
    content: url($images + 'organization.svg');
  }

  &.credential {
    content: url($images + 'credential.svg');
  }

  &.course-hat {
    content: url($icons + 'course-hat.svg');
  }

  &.course-certificate {
    content: url($icons + 'course-certificate.svg');
  }

  &.course-univerity {
    content: url($icons + 'course-univerity.svg');
  }

  &.industry {
    content: url($icons + 'industry-blue.svg');
  }

  &.course-location {
    content: url($icons + 'course-location.svg');
  }

  &.complete-profile {
    content: url($images + 'complete-profile.svg');
  }

  &.building {
    content: url($icons + 'building-blue.svg');
  }

  &.jobfunc {
    content: url($icons + 'jobfunc-blue.svg');
  }

  &.calender {
    content: url($icons + 'calender-grey.svg');
  }

  &.mobile-filled {
    content: url($images + 'mobile-filled.svg');
  }

  &.document {
    content: url($icons + 'document-blue.svg');
  }

  &.logout {
    content: url($icons + 'logout.svg');
  }

  &.caret-down {
    content: url($icons + 'caret-down.svg');
  }

  &.caret-up-ol {
    content: url($icons + 'caret-up-ol.svg');
  }

  &.caret-down-ol {
    content: url($icons + 'caret-down-ol.svg');
  }

  &.checked-tick {
    content: url($icons + 'checked-tick-blue.svg');
  }

  &.unchecked-tick {
    content: url($icons + 'unchecked-tick-blue.svg');
  }

  &.logout {
    content: url($icons + 'logout.svg');
  }

  &.processIndicatorCircle-active {
    content: url($icons + 'processIndicatorCircle-active.svg');
  }

  &.processIndicatorCircle {
    content: url($icons + 'processIndicatorCircle.svg');
  }

  &.processIndicatorCircle-completed {
    content: url($icons + 'processIndicatorCircle-completed.svg');
  }

  &.edit-pen {
    content: url($icons + 'edit-pen.svg');
  }

  &.suitecase {
    content: url($icons + 'suitecase.svg');
  }

  &.workchart {
    content: url($icons + 'workchart.svg');
  }

  &.chat {
    content: url($icons + 'chat.svg');
  }

  &.letter {
    content: url($icons + 'letter.svg');
  }

  &.books {
    content: url($icons + 'books.svg');
  }

  &.badgee {
    content: url($icons + 'badge.svg');
  }

  &.search {
    content: url($icons + 'search.svg');
  }

  &.settings {
    content: url($icons + 'settings.svg');
  }

  &.user-circle {
    content: url($icons + 'user-circle.svg');
  }

  &.support {
    content: url($icons + 'support.svg');
  }

  &.notification-bell {
    content: url($icons + 'notification-bell.svg');
  }

  &.message {
    content: url($icons + 'message.svg');
  }

  &.message-grey {
    content: url($icons + 'message-grey.svg');
  }

  &.message-grey-unfilled {
    content: url($icons + 'message-grey-unfilled.svg');
  }

  &.menu {
    content: url($icons + 'menu.svg');
  }

  &.cross {
    content: url($icons + 'cross.svg');
  }

  &.star-filled {
    content: url($icons + 'star-filled.svg');
  }

  &.star-unfilled {
    content: url($icons + 'star-unfilled.svg');
  }

  &.home {
    content: url($icons + 'home.svg');
  }

  &.pen {
    content: url($icons + 'pen.svg');
  }

  &.bulb {
    content: url($icons + 'bulb.svg');
  }

  &.delete {
    content: url($icons + 'delete.svg');
  }

  &.open {
    content: url($icons + 'open.svg');
  }

  &.graduation-cap {
    content: url($images + 'graduation-cap.svg');
  }

  &.gps {
    content: url($icons + 'gps.svg');
  }

  &.loading {
    content: url($icons + 'loading.svg');
    @include spinnerAnim;
  }

  &.info {
    content: url($icons + 'info.svg');
  }

  &.info-yellow {
    content: url($icons + 'info-yellow.svg');
  }

  &.clock {
    content: url($icons + 'clock.svg');
  }

  &.download {
    content: url($icons + 'themed-download.svg');
  }

  &.outline-bell {
    content: url($icons + 'outline-bell.svg');
  }

  &.outline-delete {
    content: url($icons + 'outline-delete.svg');
  }

  &.outline-key-reset {
    content: url($icons + 'outline-key-reset.svg');
  }

  &.success-img {
    content: url($images + 'success.svg');
  }

  &.cross-mark {
    content: url($icons + 'cross-mark.svg');
  }

  &.questionmark-circled {
    content: url($icons + 'questionmark-circled.svg');
  }

  &.close-grey {
    content: url($icons + 'close-grey.svg');
  }

  &.tickmark {
    content: url($icons + 'tickmark.svg');
  }

  &.closemark {
    content: url($icons + 'closemark.svg');
  }

  &.left-arrow {
    content: url($icons + 'arrow-back.svg');
  }

  &.checkbox-unchecked {
    height: 20px;
    width: 20px;
    border: 1px solid $themed-grey-dark;
    border-radius: 50%;
  }

  &.checkbox-checked {
    content: url($icons + 'checkbox-tick.svg');
    height: 20px;
    width: 20px;
    border: 1px solid $themed-blue;
    border-radius: 50%;
    padding: 2px;
  }

  &.giveaway {
    content: url($images + 'giveaway.svg');
  }

  &.done {
    content: url($icons + 'done.svg');
  }

  &.done-uneven-border {
    content: url($icons + 'done-uneven-border.svg');
  }

  &.outline-bell {
    content: url($icons + 'outline-bell.svg');
    display: inline-block;
    width: 26px;
    height: 26px;
  }

  &.notification-filled {
    content: url($icons + 'notification-filled.svg');
  }

  &.outline-circle-mark {
    content: url($icons + 'outline-circle-mark.svg');
  }

  &.chevron {
    content: url($icons + 'chevron.svg');
  }

  &.checklist {
    content: url($icons + 'checklist.svg');
  }

  &.clock-outline {
    content: url($icons + 'clock-outline.svg');
  }

  &.document-ports {
    content: url($icons + 'document-ports.svg');
  }

  &.trash-filled {
    content: url($icons + 'trash-filled.svg');
  }

  &.filter {
    content: url($icons + 'filter.svg');
  }

  &.approved {
    content: url($icons + 'approved.svg');
  }

  &.rejected {
    content: url($icons + 'rejected.svg');
  }

  &.pendingApproval {
    content: url($icons + 'pendingApproval.svg');
  }

  &.picture {
    content: url($icons + 'picture.svg');
  }

  &.calendar-outlined {
    content: url($icons + 'calendar-outlined.svg');
  }

  &.info-grey {
    content: url($icons + 'info-grey.svg');
    width: 20px;
  }

  &.bell-noti {
    content: url($icons + 'bell-noti.svg');
  }

  &.pf-updates {
    content: url($icons + 'pf-updates.svg');
  }

  &.remainder {
    content: url($icons + 'remainder.svg');
  }

  &.rupee-noti {
    content: url($icons + 'rupee-noti.svg');
  }

  &.stats-up {
    content: url($icons + 'stats-up.svg');
  }

  &.user-nt {
    content: url($icons + 'user-nt.svg');
  }

  &.work-noti {
    content: url($icons + 'work-noti.svg');
  }

  &.ep-info {
    content: url($icons + 'ep-info.svg');
  }

  &.message-solid {
    content: url($icons + 'message-solid.svg');
    display: inline-block;
    width: 26px;
    height: 26px;
  }

  &.notification-grey {
    content: url($icons + 'notification-grey.svg');
  }

  &.off-switch {
    content: url($icons + 'off-switch.svg');
  }

  &.on-switch {
    content: url($icons + 'on-switch.svg');
  }

  &.job-search {
    content: url($icons + 'job-search.svg');
  }

  &.person {
    content: url($icons + 'person.svg');
  }

  &.park-outline {
    content: url($icons + 'park-outline.svg');
  }

  &.google-cloud {
    content: url($icons + 'google-cloud.svg');
  }

  &.pc-microphone {
    content: url($icons + 'pc-microphone.svg');
  }

  &.pc-video {
    content: url($icons + 'pc-video.svg');
  }

  &.pc-user {
    content: url($icons + 'pc-user.svg');
  }

  &.id-card {
    content: url($icons + 'id-card.svg');
  }

  &.tick-success {
    content: url($icons + 'tick-success.svg');
  }

  &.circled-nav {
    content: url($icons + 'circled-nav.svg');
  }

  &.smiley {
    content: url($icons + 'smiley.svg');
  }

  &.circle-plus-fill {
    content: url($icons + 'circle-plus-fill.svg');
  }

  &.back {
    content: url($icons + 'back.svg');
  }

  &.repeat {
    content: url($icons + 'repeat.svg');
    width: 10px;
  }

  &.process {
    content: url($icons + 'process.svg');
  }

  &.sort {
    content: url($icons + 'columns.svg');
  }

  &.clipboard {
    content: url($icons + 'clipboard.svg');
  }

  &.kycpreview {
    content: url($images + 'kycpreview.svg');
  }

  &.beginner-badge {
    content: url($icons + 'beginner-badge.svg');
    width: 80%;
  }

  &.expert-badge {
    content: url($icons + 'expert-badge.svg');
    width: 80%;
  }

  &.intermediate-badge {
    content: url($icons + 'intermediate-badge.svg');
    width: 80%;
  }

  &.progress-step {
    content: url($icons + 'progress-step.svg');
  }

  &.incomplete-progress-step {
    content: url($icons + 'incomplete-progress-step.svg');
  }

  &.jobfunction-badge-beginner {
    content: url($icons + 'jobfunction-badge-beginner.svg');
  }

  // &.jobfunction-badge-intermediate {
  //   content: url($icons + 'jobfunction-badge-intermediate.svg');
  // }

  // &.jobfunction-badge-advanced {
  //   content: url($icons + 'jobfunction-badge-intermediate.svg');
  // }

  &.kyc-instruction {
    content: url($icons + 'kyc-instruction.svg');
  }

  &.customer-service {
    content: url($icons + 'customer-service.svg');
  }

  &.telecalling {
    content: url($icons + 'telecalling.svg');
  }

  &.technical-support {
    content: url($icons + 'technical-support.svg');
  }

  &.data-processing {
    content: url($icons + 'data-entry.svg');
  }

  &.recruitment {
    content: url($icons + 'recruitment.svg');
  }

  &.tele-sales {
    content: url($icons + 'online-reputation.svg');
  }

  &.testimonial {
    content: url($icons + 'testimonial.png');
  }

  &.website {
    content: url($icons + 'website.png');
  }

  &.sales {
    content: url($icons + 'sales.svg');
  }

  &.executive-assistance {
    content: url($icons + 'executive-assistance.svg');
  }

  &.businessman {
    content: url($icons + 'businessman.svg');
  }

  &.people {
    content: url($icons + 'people.svg');
  }

  &.person {
    content: url($icons + 'person.svg');
  }

  &.reload {
    content: url($icons + 'reload.svg');
  }

  &.ico-404 {
    content: url($icons + '404.svg');
  }

  &.plus {
    content: url($icons + 'plus.svg');
  }

  &.assessment-failed {
    content: url($icons + 'assessment-failed.svg');
  }

  &.complete-assessments {
    content: url($icons + 'complete-assessments.svg');
  }

  &.favicon {
    content: url($icons + 'favicon.png');
  }

  &.saaspay {
    content: url($images + 'saaspay.svg');
  }

  &.credit-card {
    content: url($icons + 'credit-card.svg');
  }

  &.rupee {
    content: url($icons + 'rupee.svg');
  }

  &.mic {
    content: url($icons + 'mic.svg');
  }

  &.stop-circle {
    content: url($icons + 'stop-circle.svg');
  }

  &.campaigns {
    content: url($icons + 'campaigns.svg');
  }

  &.teams {
    content: url($icons + 'teams.svg');
  }

  &.voiz-certified-medal {
    content: url($icons + 'voiz-certified-medal.svg');
  }

  &.subscription-feature-tick {
    content: url($icons + 'subscription-feature-tick.svg');
  }

  &.status {
    content: url($icons + 'status.svg');
  }

  &.more {
    content: url($icons + 'more.svg');
  }

  &.down-caret {
    content: url($images + 'down-caret.svg');
  }

  &.edit-vector {
    content: url($icons + 'edit-vector.svg');
  }

  &.qr-offline-payment {
    content: url($images + 'qr-offline-payment.png');
  }


  &.on-job {
    content: url($images + 'onjob.svg');
  }


  &.pdf {
    content: url($images + 'pdf.png');
  }

  &.phone-call {
    content: url($icons + 'phone-call.svg');
  }


  &.flight {
    content: url($icons + 'flight.svg');
  }

  &.jet {
    content: url($icons + 'jet.svg');
  }

  &.plane {
    content: url($icons + 'plane.svg');
  }

  &.onboarding {
    content: url($images + 'onboarding.svg');
  }

  &.add-video {
    content: url($images + 'add-video.svg');
  }

  &.play-video {
    content: url($images + 'play-video.svg');
  }

  &.rendering {
    content: url($images + 'rendering.svg');
  }

  &.call-placed {
    content: url($icons + 'call-placed.png');
  }

  &.recording-completed {
    content: url($icons + 'recording-completed.svg');
  }

  &.add-project {
    content: url($icons + 'add-project.svg');
  }

  &.teams-setup {
    content: url($images + 'teams.svg');
  }

  &.field-work {
    content: url($icons + 'field-work.svg');
  }

  &.facebook_logo {
    content: url($icons + 'facebooklogo.png');
  }

  &.twitter_logo {
    content: url($icons + 'xlogo.png');
  }

  &.linkedin_logo {
    content: url($icons + 'linkedinlogo.png');
  }

  &.linkedin_vector {
    content: url($icons + 'linkedin-vector.png');
  }

  &.email {
    content: url($icons + 'email.png');
  }

  &.whatsapp_logo {
    content: url($icons + 'whatsapp_logo.png');
  }

  &.no-data {
    content: url($images + 'no-data.svg');
  }

  &.quickhire {
    content: url($icons + 'quickhire.png');
  }

  &.face-recognition {
    content: url($icons + 'face-recognition.png');
  }

  &.setup-face-id {
    content: url($icons + 'setup-face-id.png');
  }

  &.headset-solid {
    content: url($icons + 'headset-solid.svg');
  }

  &.email {
    content: url($icons + 'email.svg');
  }

  &.twenty-four {
    content: url($icons + '24-hours-support.png');
  }

  &.copy {
    content: url($icons + 'copy.png');
  }

  &.direction {
    content: url($icons + 'direction.png');
  }

  &.share {
    content: url($icons + 'share.png');
  }
}

.vz-icons {
  .s10 {
    height: 12px;
    width: 12px;
  }
}