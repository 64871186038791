@import '~bootstrap/scss/bootstrap';
@import 'variables';

@import 'scss/functions';
@import 'scss/buttons';
@import 'scss/typography';
@import 'scss/icons';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');

* {
  box-sizing: border-box;
}

.debug {
  border: 1px solid $danger-color;
}

li {
  color: #8f8d8d;
}

.form-group {

  input[type='text'],
  input[type='password'],
  input[type='email'],
  textarea,
  .form-control {
    border-radius: 3px;
    padding: 10px 15px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

label {
  @include fs(21px, 28px);
  @include os;
}

.box-heading {
  background-color: $box-header-background-color;
  padding: 50px 60px;
  font: normal normal bold 28px/34px Lato;
}

html,
body {
  background-color: $body-background-color;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  color: $text-color;
  scroll-behavior: smooth;
}

.btn-primary {
  font-family: 'Poppins', sans-serif;
}

.underlined-headline {
  @include flex-center;
  text-align: center;
}

.centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tag-list {
  @include inline-list;
  flex-wrap: wrap;

  .tag {
    background: white;
    border: 1px solid $themed-blue;
    box-shadow: 0 1px 2px #00000029;
    border-radius: 5px;
    margin: 5px;
    color: $themed-dark;
    cursor: pointer;
    @include fs(20px, 24px);

    &:first-child {
      margin-left: 0;
    }

    &.active {
      color: white;
      background: $themed-blue;
    }
  }
}

.cursor_pointer,
.cursor-pointer {
  cursor: pointer;
}

.dropdown-toggle {
  font-size: inherit;
  background: transparent;
  padding: inherit !important;
  font-weight: inherit;
  border: none !important;
  color: black;
  margin: 0;
}

.dropdown-toggle:hover {
  background: transparent;
  color: black;
}

.dropdown-toggle:active {
  background: transparent;
  color: black;
}

.dropdown-toggle:focus {
  background: transparent;
  color: black;
}

.white-box {
  @include bordered-white-box;
}

.white-bg {
  background: #fff;
}

.table .thead-light th {
  background-color: #cbd7ff;
  color: #000;
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
  font-size: 15px;
  border: 0;
}

.no-border-radius {
  border-radius: 0 !important;
}

.btnadd {
  background-color: #f4f4f8;
  color: black;
  border-radius: 10px;
  padding: 10px 100px 10px 20px;
  cursor: pointer;
  border: 1px dashed #e7e7e7;
}

.btnadd1 {
  background-color: #f4f4f8;
  color: blue;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px dashed #e7e7e7;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.modal-overlay {
  top: 0;
  background: rgba(0, 0, 0, 0.7) !important;
}

input.is-invalid,
.form-control.is-invalid {
  border: 1px solid #dc3545 !important;
}

.common-empty-list-block {
  padding: 20px;
  border: 1px dashed #ddd;
  margin: 10px 0;
  text-align: center;

  a {
    color: #374bff !important;
    text-align: center !important;
    cursor: pointer;
  }
}

.text-danger {
  @include fs(18px);
}

.savebtn {
  background-color: #374bff;
  color: #f4f4f8;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #374bff;
}

.cancelbtn {
  background-color: #f4f4f8;
  color: #374bff;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #374bff;
}

.no-results {
  @include lato;
  @include fs(25px);
  width: 100%;
  color: #666;
  text-align: center;
  padding: 40px;
  border: 1px dashed #ddd;
  background: #fff;
}

.mobileview {
  display: none;
}

.maincontainer {
  overflow-x: hidden;
}

.block-item {
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #C5C5C5;
}

.headgrey {
  color: #8f8d8d;
}

.home-container {
  height: calc(100vh - 122px);
  background-color: #fff;
}

.signup-container {
  height: 100vh;
  background-color: #fff !important;
}

.react-datepicker__input-container {
  &input {
    margin: 0;
  }
}

.expert-bg {
  background-color: #bbe3c4;
  text-align: center;
}

.intermediate-bg {
  background-color: #f4e1a9;
  text-align: center;
}

.novice-bg {
  background-color: #e8babe;
  text-align: center;
}

input {
  text-align: inherit !important;
}

@include mobile {
  .section-heading {
    h1 {
      font-size: 1.5em !important;
      margin: 0;
      padding: 0;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }
}

.red {
  color: $danger-color;
}

.border-red {
  border: 1px solid $danger-color;
}

.vh-wrapper-exclude-header {
  min-height: calc(100vh - 75px);
  overflow: scroll;
}

.vh-wrapper {
  min-height: 100vh;
  background-color: $white-color;
  animation: fadeIn 1s;

  .bg-transparent {
    background-color: inherit;
  }
}

.bg-palenavy {
  background-color: $prelogin-banner-wrapper-color;
}

.error-feedback {
  display: flex;
  font-size: 12px;
  color: $danger-color;

  i {
    padding-top: 5px;
  }

  span {
    padding-top: 5px;
  }

  animation: fadeIn 1s;
}

.small {
  .error-feedback {
    display: flex;
    font-size: 9px;
    color: $danger-color;

    i {
      width: 12px;
    }

    span {
      left: 18px;
      position: absolute;
      line-height: 12px;
    }
  }
}

.text-themed-blue {
  color: $themed-blue;
}

#react-select-portals {
  position: relative;
  z-index: 3000;
  height: auto;
  width: auto;
  background-color: transparent;
}

.loading-spinner {
  animation: fadeIn 1s;
}

.fade-in {
  animation: fadeIn 1s;
}

.Toastify__progress-bar {
  background: $themed-blue !important;
  height: 2px !important;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  position: absolute;
  height: 100%;
  width: 15% !important;
  left: 0;
  display: flex;
  align-items: center;

  svg {
    margin: 0 auto;
    display: block;
  }
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}

.Toastify__toast-body {
  div:last-of-type {
    padding-left: calc(15% - 8px);
  }
}

.Toastify__toast--success .Toastify__toast-icon {
  background: #ddf7e0;
}

.Toastify__toast--error .Toastify__toast-icon {
  background: #ffdce4;
}

.badge {
  margin-right: 10px;
  font-weight: 500;

  &.themed-outline-badge {
    background-color: $white-color;
    color: #000000;
    border: 1px solid $themed-blue;
  }

  &.themed-badge {
    background-color: $themed-blue;
    color: $white-color;
  }
}

.bg-themed-ghost-white {
  background: $themed-ghost-white;
}

.bg-themed-white {
  background: $white-color;
}

.bg-themed-alice-blue {
  background: $themed-alice-blue;
}

.themed-grey-dark {
  color: $themed-grey-dark;
}

.themed-dark {
  color: $themed-dark;
}

.select-none {
  user-select: none;
}

.custom-alert {
  background: rgba(255, 52, 52, 0.08);
  border-left: 4px solid $danger-color;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: '>' !important;
  background-color: transparent !important;
  margin-right: 4px !important;
  padding-right: 0;
  color: $themed-dark;
}

.breadcrumb-item {
  font-weight: 500;
  font-size: 14px;
  padding-right: 4px !important;

  a {
    color: $themed-dark !important;
  }
}

.breadcrumb-item:last-of-type {
  a {
    color: $themed-blue !important;
  }
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0;
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: 0 !important;
}

.kycbutton {
  width: 431px !important;
  background-color: #3a50fb !important;
  color: white !important;
}

@media (max-width: 767px) {

  .children-container {
    margin-top: 75px;
  }

  .kyccontinue {
    max-width: 75%;
  }
}

.kycbutton-danger {
  width: 431px !important;
  background-color: red !important;
  color: white !important;
}

.kycsubmissiontext {
  font-size: 32px;
}

.tabActive {
  border-bottom: 3px solid #3a50fb !important;
  color: #1f1f1f;
}

.tabinActive {
  color: #707070;
}

@media only screen and (max-width: 600px) {
  .kycsubmissiontext {
    font-size: 16px;
    justify-content: center;
  }

  .kycbutton {
    width: 431px;
    background-color: #3a50fb;
    color: white;
  }

  .hide-on-mobile {
    display: none;
  }
}

.check-toggler {
  .check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $white-color;
    border: 1px solid $themed-blue;
    border-radius: 4px;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .check-container:hover input~.checkmark {
    background-color: #ccc;
  }

  .check-container input:checked~.checkmark {
    background-color: $white-color;
  }

  .check-container input:checked~.checkmark:after {
    display: block;
  }

  .check-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $themed-blue;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .check-label {
    margin-top: 12px;
  }
}

.custom-shadow {
  box-shadow: 0px 8px 16px rgba(55, 75, 255, 0.18);
}

.themed-button-pill {
  border-radius: 5px;
  padding: 5px 6px;

  &.blue-outlined {
    border: 1px solid $themed-blue;
  }

  &.blue-filled {
    border: 1px solid $themed-blue;
    background: $themed-blue;
    color: $white-color;
  }
}

.team-listing {
  display: flex;
}

.nav-tabs {
  .nav-link {
    color: #666666;
  }

  .nav-link.active {
    border-width: 0;
    border-bottom: 2px solid #007bff;
    font-weight: bold;
    color: #666666;
    background-color: transparent;
  }
}

.tabbar {
  @include tabbar;
}


.custom-table {
  border-radius: 16px;
  background-color: #fff;
  text-align: center;

  thead {
    background-color: #eff1ff !important;
    height: 81px;
  }

  tbody {

    td,
    th {
      border: none;
      border-bottom: 1px solid rgba(31, 31, 31, 0.25);
    }
  }

  th,
  td {
    vertical-align: middle;
    text-transform: none !important;
  }

  th {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #333333 !important;
  }
}

.custom-tag {
  border: 1px solid $themed-grey-dark;
  color: $themed-grey-dark;
  margin: 5px;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.meta-card {
  min-height: 100px;
  cursor: pointer;
  border-radius: 24px;
  border: 1px solid $themed-silver;
  margin-bottom: 10px;
  transition: 0.5s ease-in-out;
  padding: 25px;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 16px rgba(58, 80, 251, 0.16);
  }
}

.menu-list {
  ul {
    list-style: none;
    border-bottom: 1px solid $themed-silver;

    li {
      margin-right: 15px;
      font-size: 500;
      cursor: pointer;
      font-size: 18px;
      padding-bottom: 10px;
      min-width: 100px;
      text-align: center;
      margin-bottom: 0;

      &.active {
        color: $themed-blue;
        border-bottom: 3px solid $themed-blue;
      }
    }
  }
}

.themed-border {
  border: 1px solid $themed-blue;
  border-radius: 10px;
}

.custom-badge {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 8px;
  text-align: center;

  &.success {
    background: #abfbd682;
    color: #18AB56;
  }

  &.danger {
    color: #EB5757;
    background: #FFF0F0;
  }

  &.primary {
    background: #F8F9FE;
    color: $themed-blue;
  }

  &.secondary {
    color: $themed-grey-dark;
    background: #EDEDED;
  }

  &.warning {
    color: #FFC400;
    background: #FFF3CC;
  }
}

.user-profile-pic {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid $white-color;
  object-fit: fill;

}

.user-with-no-image {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  padding: 4px;
}

.drop-down-title {
  color: $themed-grey-dark;

  .vz-icons {
    width: 13px;
  }
}

.nav-item {
  .dropdown-menu {
    background: none;
    border: none;
  }
}

.custom-dropdown {
  background: $white-color;
  box-shadow: 0px 8px 16px rgba(58, 80, 251, 0.18);
  border-radius: 8px;
  overflow: hidden;
  min-width: 100%;
  left: -50px;

  &.center {
    left: 60px;
  }

  font-weight: normal;
  font-size: 16px;
  padding: 0;

  color: $black-color;
  background: $white-color;

  .custom-dd-item {
    user-select: none;
    cursor: pointer;
    padding: 8px 10px;
    width: 100%;
    height: 40px;
    color: #000;

    &:hover,
    &.is-active {
      background-color: $themed-ghost-white !important;

      span {
        color: $themed-blue;
      }

      .vz-icons {
        filter: $themed-blue-filter;
      }
    }

    &.is-active {
      background-color: transparent;
    }

    span {
      padding-left: 0;
      color: $themed-grey-dark;

      &.has-icon {
        padding-left: 14px !important;
      }

      font-size: 16px;
      font-weight: normal;
    }
  }
}

.section-container {
  min-height: calc(100vh - 85px);

  .header {
    border-bottom: 1px solid $themed-silver;
  }
}

.user-select-none {
  user-select: none;
}

.bg-transparent {
  background: transparent;
}

.themed-success {
  color: $themed-success;
}

.agreement-modal {
  max-height: 50vh;
  overflow: scroll;
  scroll-behavior: smooth;
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}


.image-wrapper {
  width: 180px;
  height: 180px;

  .profile-image {
    user-select: none;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $white-color;
    filter: drop-shadow(0px 4px 8px rgba(58, 80, 251, 0.26));
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .online-status {
    background-color: $themed-success;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid $white-color;
  }
}

.sidebar {
  .image-wrapper {
    width: 100px;
    height: 100px;

    .profile-image {
      width: 100px;
      height: 100px;
      border-color: #000000;
      filter: none;
      background: $white-color;
    }

    .online-status {
      background-color: $themed-success;
      width: 15px;
      height: 15px;
      right: 10px;
      top: 6px;
      border-color: #000000;
    }
  }
}

.profile-picture-holder {
  border: 1px solid $themed-grey-dark;
  padding: 10px;
  border-radius: 8px;

  .img-viewer {
    margin: 5px 0;
    height: 55px;
    width: 55px;
    background: $themed-palenavy;
    border-radius: 100px;

    .custom-icon {
      position: absolute;
      width: 55px;
      height: 55px;
      display: inline-block;
      background-color: $themed-blue;
    }

    .user-icon,
    .company-icon {
      -webkit-mask: url(./assets/images/user.svg) no-repeat 50% 50%;
      mask: url(./assets/images/user.svg) no-repeat 50% 50%;
      -webkit-mask-size: 20px;
      mask-size: 20px;
    }

    .company-icon {
      -webkit-mask: url(./assets/images/company-icon.png) no-repeat 50% 50%;
      mask: url(./assets/images/company-icon.png) no-repeat 50% 50%;

    }

    .profile-img {
      height: 55px;
      width: 55px;
      border-radius: 100px;
    }
  }

  >.fs-14 {
    font-size: 14px;
    line-height: 22px;
    padding-left: 5px;

    >.label-txt {
      font-weight: 600;
    }

    >a {
      color: $themed-blue;
      font-weight: 500;
    }

    >.desc {
      font-weight: 400;
    }
  }
}

.themed-blue-filter {
  filter: $themed-blue-filter;
}