$top-bar-background-color: #2A2933;
$body-background-color: #F1F1F1;
$box-background-color: #FCFCFC;
$box-header-background-color: #E5E1FF;
$box-background-color: #FCFCFC;
$tag-background-color: #EBEBEB;
$status-tag-background-color: #2A2933;
$prelogin-banner-wrapper-color: #EAEEFB;

$text-color: #292626;
$text-color-paragraph: #b6b6b6;
$text-color-light: #656374;
$text-color-gray: #8B8B8B;
$link-color: #3153C4;
$white-color: #ffffff;
$status-tag-text-color: $white-color;
$danger-color: #FF3434;
$black-color: #000;

$primary-button-background-color: #63B5FC;
$primary-button-text-color: #ffffff;


$left-panel-width: 340px;


$input-border-color: #c4c4c4;


$light-border-color: #dfdfdf;

$themed-blue: #3A50FB;
$themed-blue-dark: #0047D6;
$themed-grey-dark: #707070;
$themed-grey-light: #D9D9D9;
$themed-palenavy: #EAEEFB;
$themed-warning: #ffc107;
$themed-silver: #C5C5C5;
$themed-dark: #1F1F1F;
$themed-success: #18A700;
$themed-ghost-white: #EFF1FF;
$themed-alice-blue: #F8F9FE;
$themed-light: #F7F7F8;
$themed-blue-filter: invert(30%) sepia(92%) saturate(4678%) hue-rotate(231deg) brightness(99%) contrast(99%);
$themed-dark-filter: invert(48%) sepia(6%) saturate(5%) hue-rotate(341deg) brightness(88%) contrast(87%);
$themed-danger-filter: invert(35%) sepia(84%) saturate(4934%) hue-rotate(349deg) brightness(98%) contrast(104%);
$white-filter: brightness(1000%) saturate(0%);
$themed-dark-filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(327deg) brightness(95%) contrast(101%);
$themed-grey-dark-filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(341deg) brightness(93%) contrast(96%);
$black-filter: invert(7%) sepia(9%) saturate(9%) hue-rotate(335deg) brightness(96%) contrast(88%);
$themed-red-filter: invert(49%) sepia(81%) saturate(4762%) hue-rotate(339deg) brightness(98%) contrast(106%);
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px 